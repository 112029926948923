<template>
  <div class="box-items">
    <img class="img-body" src="@/assets/image/cn/btcapp.jpg" alt="">
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data () {
    return {}
  }
}
</script>

<style scoped>
.box-items{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-body{
  width: 1200px;
}
</style>
